@charset "UTF-8";
.header {
  padding: 10px 0;
  height: 60px;
  overflow: hidden;
  line-height: 40px; }

@media (max-width: 768px) {
  .header__name {
    display: none; } }

.header__title {
  display: flex;
  align-items: center;
  float: left;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 40px;
  text-rendering: auto;
  transform: translate(0, 0); }
  .header__title:before {
    display: inline-block;
    padding-right: 5px;
    content: "🛒"; }

.header__actions {
  float: right;
  padding: 8px 0;
  line-height: 24px; }
  .header__actions:after {
    clear: both;
    content: '';
    display: table; }
  .header__actions li {
    float: left;
    list-style: none; }
    .header__actions li {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid #333; }
    .header__actions li:first-child {
      border: none; }
  .header__actions .btn {
    display: block;
    margin: 0;
    color: #222;
    font-size: 1.25rem;
    line-height: 24px; }
  .header__actions .link {
    display: block;
    fill: #ccc;
    transform: translate(0, 0); }
