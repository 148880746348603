.task-form {
  margin: 50px 0 10px; }
  @media screen and (min-width: 33.75em) {
    .task-form {
      margin: 50px 0 20px; } }

.task-form__input {
  outline: none;
  border: 0;
  border-bottom: 1px dotted #666;
  border-radius: 0;
  padding: 0 0 5px 0;
  width: 100%;
  height: 50px;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 300;
  color: #222;
  background: transparent;
  -webkit-font-smoothing: antialiased; }
  @media screen and (min-width: 33.75em) {
    .task-form__input {
      height: 61px;
      font-size: 2rem; } }
  .task-form__input::placeholder {
    color: #222;
    opacity: 1; }
  .task-form__input:focus::placeholder {
    color: #777;
    opacity: 1; }
  .task-form__input::-ms-clear {
    display: none; }
