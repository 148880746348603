.btn {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: 0;
  padding: 0;
  overflow: hidden;
  transform: translate(0, 0);
  background: transparent; }
  .btn[disabled] {
    cursor: default; }

.btn--icon {
  border-radius: 40px;
  padding: 8px;
  width: 40px;
  height: 40px; }
