.sign-in {
  margin-top: 60px;
  max-width: 300px; }

.sign-in__greeting {
  text-align: center;
  margin-bottom: 20px; }

.sign-in__heading {
  margin-bottom: 36px;
  font-size: 30px;
  font-weight: 300;
  text-align: center; }

.sign-in__button {
  margin-bottom: 10px;
  border: 1px solid #555;
  width: 100%;
  height: 48px;
  font-family: inherit;
  font-size: 1.125rem;
  line-height: 48px;
  color: #222; }
  .sign-in__button:hover {
    border: 2px solid #aaa;
    line-height: 46px; }
