.task-item {
  display: flex;
  outline: none;
  border-bottom: 1px dotted #666;
  height: 60px;
  overflow: hidden;
  color: #222;
  font-size: 1.25rem;
  font-weight: 300; }
  @media screen and (min-width: 33.75em) {
    .task-item {
      font-size: 1.5rem; } }

.task-item--editing {
  border-bottom: 1px dotted #ccc; }

.cell:first-child, .cell:last-child {
  display: flex;
  flex: 0 0 auto;
  align-items: center; }

.cell:first-child {
  padding-right: 20px; }

.cell:nth-child(2) {
  flex: 1;
  padding-right: 30px;
  overflow: hidden; }

.task-item__button {
  margin-left: 5px;
  background: #f5f5f5;
  color: #222; }
  .task-item__button:first-child {
    margin: 0; }
  .task-item__button:hover {
    color: #005073; }
  .task-item__button:active {
    background: #262626; }
  .task-item__button.active {
    color: #85bf6b; }

.task-item__title {
  display: inline-block;
  position: relative;
  max-width: 100%;
  line-height: 60px;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .task-item__title:after {
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: 2px solid #85bf6b;
    width: 0;
    height: 46%;
    content: ""; }
  .task-item--completed .task-item__title {
    color: #666; }
  .task-item--completed .task-item__title:after {
    width: 100%; }

.task-item__input {
  outline: none;
  border: 0;
  padding: 0;
  width: 100%;
  height: 60px;
  color: inherit;
  font: inherit;
  background: transparent; }
  .task-item__input::-ms-clear {
    display: none; }
